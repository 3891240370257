<template>
  <div class="step1">
    <div class="title">
      {{
        this.$i18n.locale == 'ar' ?
          'يمكنك اختيار مزود خدمة او دعنا نختار لك بما يناسب احتياجاتك'
          :
          'You can choose a service provider or let us choose for you what suits your needs'
      }}
    </div>
    <ul class="nav nav-pills" id="pills-tab" role="tablist" v-if="provider == 'both'">
      <li class="nav-item active" role="presentation">
        <button data-index="Provider2" class="nav-link py-3 active" id="v-pills-Provider2-tab" data-bs-toggle="pill"
          data-bs-target="#v-pills-Provider2" type="button" role="tab" aria-controls="v-pills-Provider2"
          aria-selected="true">
          <span class=" fs-sm-4  d-flex align-items-center">
            <span class="icon"></span>
            {{ $t('letEtmaamChoice') }}
          </span>
        </button>
      </li>
      <li class="nav-item " role="presentation">
        <button data-index="Provider1" class="nav-link py-3 " id="v-pills-Provider1-tab" data-bs-toggle="pill"
          data-bs-target="#v-pills-Provider1" type="button" role="tab" aria-controls="v-pills-Provider1"
          aria-selected="true">
          <span class=" fs-sm-4 d-flex align-items-center">
            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 0C5.89331 0 3.375 2.46235 3.375 5.5C3.375 8.53765 5.89331 11 9 11C12.1067 11 14.625 8.53765 14.625 5.5C14.625 2.46235 12.1067 0 9 0ZM2.39062 13.2C1.75669 13.2 1.14871 13.4462 0.700397 13.8844C0.252084 14.3227 0.000149161 14.9171 0 15.537V15.95C0 18.0153 1.09238 19.5619 2.7675 20.559C4.41563 21.5397 6.62962 22 9 22C11.3704 22 13.5844 21.5397 15.2325 20.559C16.9076 19.5619 18 18.0153 18 15.95V15.537C18 15.23 17.9382 14.9261 17.818 14.6425C17.6979 14.3589 17.5218 14.1013 17.2998 13.8843C17.0778 13.6673 16.8142 13.4951 16.5242 13.3777C16.2341 13.2603 15.9233 13.1999 15.6094 13.2H2.39062Z"
                fill="white" />
            </svg>
            {{ $t('Choicebymyself') }}
          </span>
        </button>
      </li>

    </ul>
    <ul class="nav nav-pills" id="pills-tab" role="tablist" v-else>
      <li class="nav-item " role="presentation" v-if="provider == 'etmaam'">
        <button :class="[provider == 'etmaam' ? 'active' : '']" data-index="Provider2" class="nav-link py-3 "
          id="v-pills-Provider2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Provider2" type="button" role="tab"
          aria-controls="v-pills-Provider2" aria-selected="true">
          <span class=" fs-sm-4  d-flex align-items-center">
            <span class="icon"></span>
            {{ $t('letEtmaamChoice') }}
          </span>
        </button>
      </li>
      <li class="nav-item active" role="presentation" v-if="provider != 'etmaam'">
        <button :class="[provider != 'etmaam' ? 'active' : '']" data-index="Provider1" class="nav-link py-3"
          id="v-pills-Provider1-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Provider1" type="button" role="tab"
          aria-controls="v-pills-Provider1" aria-selected="true">
          <span class=" fs-sm-4 d-flex align-items-center">
            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 0C5.89331 0 3.375 2.46235 3.375 5.5C3.375 8.53765 5.89331 11 9 11C12.1067 11 14.625 8.53765 14.625 5.5C14.625 2.46235 12.1067 0 9 0ZM2.39062 13.2C1.75669 13.2 1.14871 13.4462 0.700397 13.8844C0.252084 14.3227 0.000149161 14.9171 0 15.537V15.95C0 18.0153 1.09238 19.5619 2.7675 20.559C4.41563 21.5397 6.62962 22 9 22C11.3704 22 13.5844 21.5397 15.2325 20.559C16.9076 19.5619 18 18.0153 18 15.95V15.537C18 15.23 17.9382 14.9261 17.818 14.6425C17.6979 14.3589 17.5218 14.1013 17.2998 13.8843C17.0778 13.6673 16.8142 13.4951 16.5242 13.3777C16.2341 13.2603 15.9233 13.1999 15.6094 13.2H2.39062Z"
                fill="white" />
            </svg>
            {{ $t('Choicebymyself') }}
          </span>
        </button>
      </li>

    </ul>
    <div class="tab-content mt-3" id="pills-tabContent">
      <div :class="[provider == 'etmaam' || provider == 'both' ? 'active show' : '']" class="tab-pane fade show " id="v-pills-Provider2"
        role="tabpanel" aria-labelledby="v-pills-Provider2-tab">
        <div class="select-provider row mb-3">
              <div class="col-md-6">
                <div class="title">
                  {{
        this.$i18n.locale == 'ar' ?
          'اختر الفرع المطلوب'
          :
          'Select the desired branche'
      }}
                </div>
                <select @keydown="handleKeydown" v-model="$store.state.packageOrder.step3.BranchId" name="provider" id="provider">
                  <option @click="SetBranch(item.id)" :value="item.id" v-for="item in EtmaamBranches" :key="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
        </div>
        <div class="thanks-for-select-etmaam">
          <img style="width: 50px;" src="@/assets/img/global/etmaam-logo.svg" alt="">
          <div>
            {{ $t('Thank you for choosing us! You will get the best services that meet your needs !') }}
          </div>
        </div>
      </div>
      <div :class="[provider != 'etmaam' && provider != 'both' ? 'active show' : '']" class="tab-pane fade " id="v-pills-Provider1"
        role="tabpanel" aria-labelledby="v-pills-Provider1-tab">
        <div class="select-provider row mb-3">
              <div class="col-md-6">
                <div class="title">
                  {{
        this.$i18n.locale == 'ar' ?
          'اختر مزود الخدمة'
          :
          'Select Service Provider'
      }}
                </div>
                <select @keydown="handleKeydown" name="provider" id="provider">
                  <option @click="updatebranches(item.id)" :value="item.id" v-for="item in Providers" :key="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <div class="title">
                  {{
        this.$i18n.locale == 'ar' ?
          'اختر الفرع المطلوب'
          :
          'Select the desired branche'
      }}
                </div>
                <select @keydown="handleKeydown" v-model="$store.state.packageOrder.step3.BranchId" name="provider" id="provider">
                  <option @click="SetBranch(item.id)" :value="item.id" v-for="item in branches" :key="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
        </div>
      </div>


    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      Providers: [],
      branches: [],
      EtmaamBranches: [],
    }
  },
  props: ['provider'],
  methods:{
    SetBranch(id){
      this.$store.state.serviceOrder.step1.BranchId = id;
    },
    handleKeydown(event) {
          if (event.key === 'Tab') {
            event.preventDefault(); // Prevent the default tab action
          }
    },
    getProviders(packageId){
      axios
        .get(`/user/service-providers/offer-packages/${packageId}`,{
          headers:{
            "Authorization": `Bearer ${this.$store.state.Token}`
          }
        })
        .then((res) => {
          res.data.data.forEach(el => {
            if(el.id != 1){
              this.Providers.push(el)
            }
          });
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    getEtmaamBranch(packageId){
      axios
        .get(`/user/branches/offer-packages/${packageId}?service_provider_id=${1}`,{
          headers:{
            "Authorization": `Bearer ${this.$store.state.Token}`
          }
        })
        .then((res) => {
          this.EtmaamBranches = res.data.data;
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    getBranch(packageId,provider_id){
      axios
        .get(`/user/branches/offer-packages/${packageId}?service_provider_id=${provider_id}`,{
          headers:{
            "Authorization": `Bearer ${this.$store.state.Token}`
          }
        })
        .then((res) => {
          this.branches = res.data.data;
        })
        .catch(function (error) {
          console.log('Error: ', error);
        });
    },
    updatebranches(id){
      let packageId = this.$route.params.id;
      this.getBranch(packageId,id);
    }
  },
  mounted(){
   
  let packageId = this.$route.params.id;
   this.getProviders(packageId);
   this.getEtmaamBranch(packageId);
   this.getBranch(packageId,1);
  }


};
</script>
